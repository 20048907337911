import { defineComponent } from '@vue/composition-api';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb.vue';
import { RoutingRouteEnum } from '../../../../../../router/routes.enum';
import BaseText from '../../../../../../components/text/BaseText.vue';
import {
  ZidButton,
  ZidIcon,
  ZidInput,
  ZidTextarea,
  ZidModal,
  ZidLoader,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import Multiselect from 'vue-multiselect';
import useWebhooksManagement from '../../../../helpers/hooks/webhooks-management/useWebhooksManagement';
import BaseIcon from '../../../../../../components/base-icon/BaseIcon.vue';
import DeleteIcon from '../../../../../../assets/icons/applications/DeleteIcon.vue';
import EditIcon from '../../../../../../assets/icons/applications/EditIcon.vue';

export default defineComponent({
  components: {
    BreadCrumb,
    BaseText,
    ZidButton,
    ZidIcon,
    ZidInput,
    ZidTextarea,
    Multiselect,
    BaseIcon,
    DeleteIcon,
    EditIcon,
    ZidModal,
    ZidLoader,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    enum RoutingEnum {
      CreateApplication_PlansManagement = RoutingRouteEnum.CreateApplication_PlansManagement,
      CreateApplication_Publish = RoutingRouteEnum.CreateApplication_Publish,
    }
    return {
      RoutingEnum,
      ...useWebhooksManagement(),
    };
  },
});
