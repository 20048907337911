import { reactive, computed, ref, onBeforeMount, onMounted } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, required, maxLength, minLength } from '@vuelidate/validators';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { AppDetailsInterface } from '../../../../../store/modules/application/applicationState.type';
import { CreateAppDetailInputs, EmbeddedAppScopeEnum } from '../../../types/create-app/createApp.enum';
import ApplicationModel from '@/api/models/ApplicationModel';
import { isValidURL, isValidURLWithLocalhost } from '@/helpers/regex/URLs';
import { getFileFromURL } from '../../../../../api/top-level-apis/helpers/fileFromURL';

const { useGetters } = createNamespacedHelpers('application');

const useCreateAppDetails = () => {
  let application: ApplicationModel | null = null;
  let applicationId = null;
  let globalScreenshotsAr: string[] = [];
  let globalScreenshotsEn: string[] = [];
  const MAX_SCREENSHOTS_NUMBER = 10;
  const MIN_SCREENSHOTS_NUMBER = 1;
  const iconFileNameFromGlobal = ref('');
  const isIconSpecsValid = ref(true);
  const isArScreenshotsInvalid = ref(false);
  const isEnScreenshotsInvalid = ref(false);
  const isInitialArabicScreenshotsLoading = ref(false);
  const isInitialEnglishScreenshotsLoading = ref(false);
  const hasEmbeddedAppsScope = ref(false);
  const mdAppLink = ref('');
  const { inProgressFullApp } = useGetters(['inProgressFullApp']);

  const appScopesList = reactive<Record<string, Record<string | number, any>[]>>({ list: [] });
  if (inProgressFullApp.value) {
    const {
      payload: { app, scopeGroups },
    } = inProgressFullApp.value;
    application = app;
    applicationId = app.id;
    iconFileNameFromGlobal.value = app.icon instanceof File ? URL.createObjectURL(app.icon) : app.icon;
    globalScreenshotsAr = application?.screen_shots_ar?.length ? application?.screen_shots_ar?.split(',') : [];
    globalScreenshotsEn = application?.screen_shots_en?.length ? application?.screen_shots_en?.split(',') : [];
    mdAppLink.value = inProgressFullApp.value.payload?.app_url ?? '';
    appScopesList.list = [...scopeGroups];
  }

  const createAppDetailsState = reactive<AppDetailsInterface>({
    appURL: application?.url ?? '',
    redirectURL: application?.installation_url ?? '',
    callbackURL: application?.redirect_uri ?? '',
    isEmbedded: application?.is_embedded ?? false,
    description: application?.description ?? '',
    descriptionArabic: application?.description_ar ?? '',
    shortDesciption: application?.short_description ?? '',
    shortDesciptionArabic: application?.short_description_ar ?? '',
    developerName: application?.developer_name_en ?? '',
    developerNameArabic: application?.developer_name_ar ?? '',
    videoURL: application?.video ?? '',
    appScreenshotsAr: [...globalScreenshotsAr],
    appScreenshotsEn: [...globalScreenshotsEn],
    iconFile: application?.icon ?? null,
    storeLine: '',
  });

  const originalArabicScreenshots = reactive<Record<string, File[]>>({ list: [] });
  const originalEnglishScreenshots = reactive<Record<string, File[]>>({ list: [] });

  let initialArabicScreenshots = reactive([
    ...createAppDetailsState.appScreenshotsAr.map((screen: string | File) => {
      if (typeof screen === 'string') {
        return new File([], screen, {
          type: 'image/jpg',
        });
      } else return screen;
    }),
  ]);

  let initialEnglishScreenshots = reactive([
    ...createAppDetailsState.appScreenshotsEn.map((screen: string | File) => {
      if (typeof screen === 'string') {
        return new File([], screen, {
          type: 'image/jpg',
        });
      } else return screen;
    }),
  ]);

  const rules = {
    appURL: {
      required,
      appURL: helpers.withMessage('Application URL is required', (value: string) => isValidURL(value)),
    },
    redirectURL: {
      required,
      redirectURL: helpers.withMessage('Redirect URL is not valid', (value: string) => isValidURLWithLocalhost(value)),
    },
    callbackURL: {
      required,
      callbackURL: helpers.withMessage('Callback URL is required', (value: string) => isValidURLWithLocalhost(value)),
    },
    isEmbedded: {},
    description: {
      msg1: helpers.withMessage('Description is required', required),
      msg2: helpers.withMessage('Description exceeds the limit', maxLength(4000)),
    },
    descriptionArabic: {
      msg1: helpers.withMessage('من فضلك, اوصف التطبيق', required),
      msg2: helpers.withMessage('الوصف تخطى المدى المسموح', maxLength(4000)),
    },
    shortDesciption: {
      msg1: helpers.withMessage('Short description is required', required),
      msg2: helpers.withMessage('Short description exceeds the allowed size', maxLength(100)),
    },
    shortDesciptionArabic: {
      msg1: helpers.withMessage('من فضلك، ادخل وصف مختصر للتطبيق', required),
      msg2: helpers.withMessage('الوصف المختصر يتخطى المساحة المسموحة', maxLength(100)),
    },
    developerName: {
      required,
      developerName: helpers.withMessage('Please developer name in English', (value: string) => {
        return /^[A-Za-z0-9 _]*[A-Za-z]+[A-Za-z0-9 _]*$/.test(value);
      }),
    },
    developerNameArabic: {
      required,
      developerNameArabic: helpers.withMessage('من فضلك, ادخل اسم المطور باللغة العربية', (value: string) => {
        return /^[\u0621-\u064A0-9 ]+$/.test(value);
      }),
    },
    videoURL: {
      videoURL: helpers.withMessage('Video URL is not valid', (value: string) => isValidURL(value) || value === ''),
    },
    appScreenshotsAr: {},
    appScreenshotsEn: {},
    iconFile: {
      required,
    },
    storeLine: {},
  };

  const v$ = useVuelidate(rules, createAppDetailsState);

  onBeforeMount(async () => {
    isInitialArabicScreenshotsLoading.value = true;
    originalArabicScreenshots.list = await getArabicScreenshotsFiles();
    isInitialArabicScreenshotsLoading.value = false;

    hasEmbeddedAppsScope.value = appScopesList.list
      .flatMap((item) => item.scopes)
      .filter((scope) => scope.is_granted)
      .some((scope) => scope.name === EmbeddedAppScopeEnum.EmbeddedAppScope);
  });

  onMounted(async () => {
    isInitialEnglishScreenshotsLoading.value = true;
    originalEnglishScreenshots.list = await getEnglishScreenshotsFiles();
    isInitialEnglishScreenshotsLoading.value = false;
  });

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: CreateAppDetailInputs) => {
    v$.value[dataProperty].$touch();
    createAppDetailsState[dataProperty] = changedValue.value;
  };

  const toggleIsEmbeddedOption = () => {
    createAppDetailsState.isEmbedded = !createAppDetailsState.isEmbedded;
  };

  const onIconSelection = (fileInputEvent: Event) => {
    const input = fileInputEvent.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const appIcon = new Image();
    appIcon.src = URL.createObjectURL(input.files[0]);

    appIcon.onerror = () => {
      isIconSpecsValid.value = false;
      return;
    };

    appIcon.onload = () => {
      if (appIcon.width !== appIcon.height || appIcon.width > 250 || appIcon.height > 250) {
        isIconSpecsValid.value = false;
        return;
      }
      if (input.files) {
        createAppDetailsState.iconFile = input.files[0];
        iconFileNameFromGlobal.value = URL.createObjectURL(input.files[0]);
        isIconSpecsValid.value = true;
      }
    };
  };

  const areArabicFiles = computed(() => originalArabicScreenshots.list.length > 0);

  const isSaveDisabled = computed(() => {
    const isIconValid = () =>
      createAppDetailsState.iconFile instanceof File || typeof createAppDetailsState.iconFile === 'string';
    return (
      v$.value.$invalid ||
      (createAppDetailsState.isEmbedded && !hasEmbeddedAppsScope.value) ||
      !isIconValid() ||
      isArScreenshotsInvalid.value ||
      isEnScreenshotsInvalid.value
    );
  });

  const showMessageForDescriptionArEnField = computed(() => {
    return (
      (v$.value.description.$dirty ||
        v$.value.descriptionArabic.$dirty ||
        v$.value.shortDesciption.$dirty ||
        v$.value.shortDesciptionArabic.$dirty) &&
      (v$.value.description.$invalid ||
        v$.value.descriptionArabic.$invalid ||
        v$.value.shortDesciption.$invalid ||
        v$.value.shortDesciptionArabic.$invalid)
    );
  });

  const showMessageDeveloperNameField = computed(() => {
    return (
      (v$.value.developerName.$dirty || v$.value.developerNameArabic.$dirty) &&
      (v$.value.developerName.$invalid || v$.value.developerNameArabic.$invalid)
    );
  });

  const onArabicScreenshotsChange = (images: File[]) => {
    const newImages = images.filter(
      (screen) =>
        originalArabicScreenshots.list.findIndex((originalScreen) => originalScreen.name === screen.name) == -1,
    );

    originalArabicScreenshots.list = [...originalArabicScreenshots.list, ...newImages];

    const areFiles = originalArabicScreenshots.list.every((file) => file.type.includes('image/'));

    if (images.length > MAX_SCREENSHOTS_NUMBER || images.length < MIN_SCREENSHOTS_NUMBER || !areFiles) {
      isArScreenshotsInvalid.value = true;
    } else {
      isArScreenshotsInvalid.value = false;
    }
  };

  const onArabicScreenshotsRemove = (image: File) => {
    const screenIndex = originalArabicScreenshots.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalArabicScreenshots.list.splice(screenIndex, 1);
      const areFiles = originalArabicScreenshots.list.every((file) => file.type.includes('image/'));

      if (
        originalArabicScreenshots.list.length > MAX_SCREENSHOTS_NUMBER ||
        originalArabicScreenshots.list.length < MIN_SCREENSHOTS_NUMBER ||
        !areFiles
      ) {
        isArScreenshotsInvalid.value = true;
      } else {
        isArScreenshotsInvalid.value = false;
      }
    }
  };

  const onEnglishScreenshotsChange = (images: File[]) => {
    const newImages = images.filter(
      (screen) =>
        originalEnglishScreenshots.list.findIndex((originalScreen) => originalScreen.name === screen.name) == -1,
    );

    originalEnglishScreenshots.list = [...originalEnglishScreenshots.list, ...newImages];
    const areFiles = originalEnglishScreenshots.list.every((file) => file.type.includes('image/'));

    if (images.length > MAX_SCREENSHOTS_NUMBER || !areFiles) {
      isEnScreenshotsInvalid.value = true;
    } else {
      isEnScreenshotsInvalid.value = false;
    }
    return [];
  };

  const onEnglishScreenshotsRemove = (image: File) => {
    const screenIndex = originalEnglishScreenshots.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalEnglishScreenshots.list.splice(screenIndex, 1);
      const areFiles = originalEnglishScreenshots.list.every((file) => file.type.includes('image/'));

      if (originalEnglishScreenshots.list.length > MAX_SCREENSHOTS_NUMBER || !areFiles) {
        isEnScreenshotsInvalid.value = true;
      } else {
        isEnScreenshotsInvalid.value = false;
      }
    }
  };

  const getArabicScreenshotsFiles = async () => {
    return Promise.all(
      createAppDetailsState.appScreenshotsAr.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialArabicScreenshots = [];
        return [];
      });
  };

  const getEnglishScreenshotsFiles = async () => {
    return Promise.all(
      createAppDetailsState.appScreenshotsEn.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialEnglishScreenshots = [];
        return [];
      });
  };

  return {
    inProgressFullApp,
    createAppDetailsState,
    v$,
    isSaveDisabled,
    iconFileNameFromGlobal,
    isIconSpecsValid,
    applicationId,
    showMessageForDescriptionArEnField,
    showMessageDeveloperNameField,
    isArScreenshotsInvalid,
    isEnScreenshotsInvalid,
    initialArabicScreenshots,
    initialEnglishScreenshots,
    isInitialArabicScreenshotsLoading,
    isInitialEnglishScreenshotsLoading,
    originalArabicScreenshots,
    originalEnglishScreenshots,
    areArabicFiles,
    mdAppLink,
    hasEmbeddedAppsScope,
    onIconSelection,
    onInputDataChange,
    onArabicScreenshotsChange,
    onEnglishScreenshotsChange,
    onArabicScreenshotsRemove,
    onEnglishScreenshotsRemove,
    toggleIsEmbeddedOption,
  };
};

export default useCreateAppDetails;
