import httpClient from '../../../../../api/httpClient';
import { WebhookGroup } from '../../../types/create-app/createApp.enum';
import AddWebhookGroupResponseModel from '../../../models/AddWebhookGroupResponseModel';
import DeleteWebhookResponseModel from '../../../models/DeleteWebhookResponseModel';

const ENDPOINT_URL = '/market';

export const setWebhookGroupDataToApi = (webhookGroupInfo: WebhookGroup, applicationID: number | string) => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/multiple/${applicationID}/webhook`, webhookGroupInfo)
    .then((response) => new AddWebhookGroupResponseModel(response))
    .catch((error) => error);
};

export const deleteWebhookGroup = (webhookGroupID: string, applicationID: number | string) => {
  return httpClient
    .delete(`${ENDPOINT_URL}/delete/${applicationID}/webhook/group`, {
      data: {
        webhook: {
          group_id: webhookGroupID,
        },
      },
    })
    .then((response) => new DeleteWebhookResponseModel(response))
    .catch((error) => error);
};
