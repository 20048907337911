<template>
  <div class="px-4 py-6 my-8 mx-6 bg-white-pureWhite shadow-md">
    <zid-breadcrumb>
      <zid-breadcrumb-item
        :route="
          RoutingRouteEnum.CreateApplication_GeneralSettings_Normal ||
          RoutingRouteEnum.CreateApplication_GeneralSettings_Javascript
        "
      >
        <base-text textStyle="h4"> General Settings </base-text>
      </zid-breadcrumb-item>
      <zid-breadcrumb-item
        :route="RoutingRouteEnum.CreateApplication_AppDetails"
        :disabled="!stepsCompleted.includes(RoutingRouteEnum.CreateApplication_AppDetails)"
      >
        <base-text textStyle="h4"> Application Details </base-text>
      </zid-breadcrumb-item>
      <zid-breadcrumb-item
        :route="RoutingRouteEnum.CreateApplication_WebhookManagement"
        :disabled="!stepsCompleted.includes(RoutingRouteEnum.CreateApplication_WebhookManagement)"
      >
        <base-text textStyle="h4"> Webhook Management </base-text>
      </zid-breadcrumb-item>
      <zid-breadcrumb-item
        :route="RoutingRouteEnum.CreateApplication_PlansManagement"
        :disabled="!stepsCompleted.includes(RoutingRouteEnum.CreateApplication_PlansManagement)"
      >
        <base-text textStyle="h4"> Plans Management </base-text>
      </zid-breadcrumb-item>
      <zid-breadcrumb-item
        :route="RoutingRouteEnum.CreateApplication_Publish"
        :disabled="!stepsCompleted.includes(RoutingRouteEnum.CreateApplication_Publish)"
      >
        <base-text textStyle="h4"> Request to Publish </base-text>
      </zid-breadcrumb-item>
    </zid-breadcrumb>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { RoutingRouteEnum } from '../../../../router/routes.enum';
import { ZidBreadcrumb, ZidBreadcrumbItem } from '@zidsa/ui';
import BaseText from '../../../../components/text/BaseText.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters } = createNamespacedHelpers('application'); // module name

export default defineComponent({
  props: {
    disabledItemsList: {
      type: Array,
      default: [],
    },
  },
  components: {
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    BaseText,
  },
  setup() {
    const { stepsCompleted } = useGetters(['stepsCompleted']);
    return {
      RoutingRouteEnum,
      stepsCompleted,
    };
  },
});
</script>
