<template>
  <div
    class="flex col-span-12 md:col-span-6 lg:col-span-4 p-2 hover:bg-white-pureWhite rounded-md transition duration-300 ease-out"
  >
    <div class="flex flex-col">
      <base-text textStyle="h4" classes="text-neutrals-black mb-3"> {{ sectionName }} </base-text>
      <zid-radio
        :value="readScopeId"
        :name="sectionName"
        :checked="isReadChecked"
        :disabled="isReadDisabled"
        class="mb-2"
        @click="onLocalReadRadioButtonSelected"
      >
        <base-text textStyle="h5" classes="text-neutrals-black"> Read </base-text>
      </zid-radio>
      <zid-radio
        :value="readWriteScopeId"
        :name="sectionName"
        :checked="isReadWriteChecked"
        :disabled="isReadWriteDisabled"
        class="mb-2"
        @click="onLocalReadWriteRadioButtonSelected"
      >
        <base-text textStyle="h5" classes="text-neutrals-black">
          {{ `Read & Write` }}
        </base-text>
      </zid-radio>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import { ZidIcon, ZidRadio } from '@zidsa/ui';
import BaseText from '../../../../components/text/BaseText.vue';
import { ScopeEnum } from '../../types/create-app/createApp.enum';

export default defineComponent({
  emits: [
    'readRadioButtonSelected',
    'readRadioDoubleSelect',
    'readWriteRadioButtonSelected',
    'readWriteRadioDoubleSelect',
  ],
  props: {
    sectionName: {
      type: String,
      required: true,
      default: '',
    },
    scopes: {
      type: [],
      required: true,
      default: [],
    },
  },
  components: {
    ZidIcon,
    ZidRadio,
    BaseText,
  },
  setup(props, { emit }) {
    const isReadChecked = ref(false);
    const isReadWriteChecked = ref(false);
    const isReadDisabled = ref(false);
    const isReadWriteDisabled = ref(false);
    const readScopeId = ref('');
    const readWriteScopeId = ref('');

    if (props.scopes.length === 1) {
      if (props.scopes[0].type === ScopeEnum.read) {
        isReadWriteDisabled.value = true;
        readScopeId.value = `${props.scopes[0].id}`;
        isReadChecked.value = props.scopes[0].is_granted;
      } else {
        isReadDisabled.value = true;
        readWriteScopeId.value = `${props.scopes[0].id}`;
        isReadWriteChecked.value = props.scopes[0].is_granted;
      }
    } else if (props.scopes.length > 1) {
      let readId = -1;
      let readWriteId = -1;
      props.scopes.forEach((scope) => {
        if (scope.type === ScopeEnum.read) readId = scope.id;
        else if (scope.type === ScopeEnum.readWrite) readWriteId = scope.id;
      });
      props.scopes.forEach((scope) => {
        if (scope.type === ScopeEnum.read) {
          readScopeId.value = `${readId}`;
          isReadChecked.value = scope.is_granted;
        } else {
          readWriteScopeId.value = `${readId}-${readWriteId}`;
          isReadWriteChecked.value = scope.is_granted;
        }
      });
    }

    const onLocalReadRadioButtonSelected = ($event) => {
      if (isReadChecked.value) {
        isReadChecked.value = false;
        emit('readRadioDoubleSelect', readScopeId.value);
        return;
      } else {
        isReadChecked.value = true;
        isReadWriteChecked.value = false;
        let readWriteId = -1;
        if (props.scopes.length > 1) {
          for (let i = 0; i < props.scopes.length; i++) {
            if (props.scopes[i].type === ScopeEnum.readWrite) {
              readWriteId = props.scopes[i].id;
            }
          }
        }
        emit('readRadioButtonSelected', $event, readWriteId);
      }
    };

    const onLocalReadWriteRadioButtonSelected = ($event) => {
      let readId = 0;
      let writeId = 0;
      if (props.scopes.length === 1 && props.scopes[0].type === ScopeEnum.readWrite) {
        writeId = Number($event.value);
      } else {
        readId = Number($event.value.split('-')[0]);
        writeId = Number($event.value.split('-')[1]);
      }
      if (isReadWriteChecked.value) {
        isReadWriteChecked.value = false;
        emit('readWriteRadioDoubleSelect', readId, writeId);
        return;
      } else {
        isReadWriteChecked.value = true;
        isReadChecked.value = false;
        emit('readWriteRadioButtonSelected', readId, writeId);
      }
    };

    return {
      isReadChecked,
      isReadWriteChecked,
      isReadDisabled,
      isReadWriteDisabled,
      readScopeId,
      readWriteScopeId,
      onLocalReadRadioButtonSelected,
      onLocalReadWriteRadioButtonSelected,
    };
  },
});
</script>
