import { reactive } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { JavascriptAppInterface } from '../../../../../store/modules/application/applicationState.type';

const { useGetters } = createNamespacedHelpers('application'); // module name

const useCreateAppGeneralSettinsJavascript = () => {
  const { generalSettingsStep } = useGetters(['generalSettingsStep']);

  const generalSettingsFromGlobal = JSON.parse(JSON.stringify(generalSettingsStep));

  const createAppGeneralSettings = reactive<JavascriptAppInterface>(
    generalSettingsFromGlobal.value.javascriptApp || {
      customCode: '',
      updateCode: '',
      customLocation: '',
    },
  );

  const rules = {
    customCode: {},
    updateCode: {},
    customLocation: {},
  };

  const v$ = useVuelidate(rules, createAppGeneralSettings);

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: 'customCode' | 'updateCode' | 'customLocation',
  ) => {
    v$.value[dataProperty].$touch();
    createAppGeneralSettings[dataProperty] = changedValue.value;
  };

  return {
    createAppGeneralSettings,
    v$,
    onInputDataChange,
  };
};

export default useCreateAppGeneralSettinsJavascript;
