import BaseModel from '../../../api/models/BaseModel';
import { WebhookResponse } from '../types/create-app/createApp.enum';

class DeleteWebhookResponseModel extends BaseModel {
  public deleted: Record<string, WebhookResponse[]>;

  constructor(arg: any) {
    super(arg);
    this.deleted = arg.deleted;
  }
}

export default DeleteWebhookResponseModel;
