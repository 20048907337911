import httpClient from '../../../../../api/httpClient';
import { AppListingStatusProperty } from '../../../types/create-app/createApp.enum';
import PublishAppResponseModel from '../../../models/PublishAppResponseModel';

const ENDPOINT_URL = '/market/app/update/listing';

export const sendAppForRewview = (applicationID: number | string) => {
  return httpClient
    .post(`${ENDPOINT_URL}/${applicationID}`, {
      listing_status: AppListingStatusProperty.RequestToPublish,
    })
    .then((response) => new PublishAppResponseModel(response))
    .catch((error) => error);
};
