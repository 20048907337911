<template>
  <div class="javascript-general-settings-step">
    <bread-crumb
      :disabledItemsList="[
        RoutingRouteEnum.CreateApplication_AppDetails,
        RoutingRouteEnum.CreateApplication_WebhookManagement,
        RoutingRouteEnum.CreateApplication_PlansManagement,
        RoutingRouteEnum.CreateApplication_Publish,
      ]"
    />
    <div class="my-8 mx-6 p-8 rounded-lg shadow-md bg-white-pureWhite">
      <div class="flex flex-col">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-6">
            <div class="flex flex-col">
              <base-text textStyle="h3" classes="text-neutrals-black mb-3"> Script Code </base-text>
              <base-text textStyle="p3" classes="text-neutrals-black">
                The Javascript Code that will be used in the merchant store.
              </base-text>
            </div>
          </div>
          <div class="p-4 bg-white col-span-12 lg:col-span-6">
            <form>
              <fieldset class="mb-3 md:mb-5">
                <label for="customCode">
                  <base-text textStyle="h5" classes="flex mb-1"> Custom Code </base-text>
                </label>
                <zid-input
                  :value="v$.customCode.$model"
                  class="create-app-zid-input"
                  id="customCode"
                  name="custom code"
                  @change="onInputDataChange($event, 'customCode')"
                />
              </fieldset>
              <fieldset class="mb-3 md:mb-5">
                <label for="updateCode">
                  <base-text textStyle="h5" classes="flex mb-1"> Update Code </base-text>
                </label>
                <div class="general-settings-text-area-container bg-white">
                  <zid-textarea
                    :value="v$.updateCode.$model"
                    name="Update code"
                    class="general-settings-text-area"
                    @change="onInputDataChange($event, 'updateCode')"
                  />
                </div>
              </fieldset>
              <fieldset class="mb-3 md:mb-5">
                <label for="customLocation">
                  <base-text textStyle="h5" classes="flex mb-1"> Custom Location </base-text>
                </label>
                <zid-input
                  :value="v$.customLocation.$model"
                  class="create-app-zid-input"
                  id="customLocation"
                  name="custom location"
                  @change="onInputDataChange($event, 'customLocation')"
                />
              </fieldset>
              <fieldset>
                <div class="flex justify-end mt-6">
                  <div class="w-full lg:w-5/6 flex flex-col lg:flex-row">
                    <zid-button type="neutral" class="lg:w-3/6 lg:me-4" @click.prevent="onBackClicked">
                      <base-text textStyle="h4" classes="p-2 text-neutrals-black"> Back </base-text>
                    </zid-button>
                    <zid-button class="lg:w-3/6 mt-3 lg:mt-0" @click.prevent="onSubmit">
                      <base-text textStyle="h4" classes="p-2 text-white-pureWhite">
                        {{ `Save & Continue` }}
                      </base-text>
                    </zid-button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { ZidInput, ZidTextarea, ZidButton } from '@zidsa/ui';
import BaseText from '../../../../../../components/text/BaseText.vue';
import useCreateAppGeneralSettinsJavascript from '../../../../helpers/hooks/create-app-general-settings/useCreateAppGeneralSettingsJavascript.ts';
import router from '../../../../../../router/index';
import { RoutingRouteEnum } from '../../../../../../router/routes.enum';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
const { useActions } = createNamespacedHelpers('application'); // module name

export default defineComponent({
  components: {
    ZidInput,
    BaseText,
    ZidTextarea,
    ZidButton,
    BreadCrumb,
  },
  setup() {
    let { createAppGeneralSettings, v$, onInputDataChange } = useCreateAppGeneralSettinsJavascript();

    const { addStepCompleted } = useActions(['addStepCompleted']);

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.CreateApplication });
    };

    const onSubmit = () => {
      const generalSettingsData = {
        customCode: v$.value.customCode.$model,
        updateCode: v$.value.updateCode.$model,
        customLocation: v$.value.customLocation.$model,
      };
      addStepCompleted(RoutingRouteEnum.CreateApplication_AppDetails);
      router.push({ name: RoutingRouteEnum.CreateApplication_AppDetails });
    };

    return {
      RoutingRouteEnum,
      createAppGeneralSettings,
      v$,
      onInputDataChange,
      onBackClicked,
      onSubmit,
    };
  },
});
</script>

<style lang="scss" scoped>
.javascript-general-settings-step {
  .general-settings-text-area-container {
    height: 200px;
    .general-settings-text-area {
      height: 100%;
    }
    .general-settings-text-area::v-deep textarea {
      height: 100% !important;
    }
  }
}
</style>
