import BaseModel from '../../../api/models/BaseModel';
import PlanModel from '../models/PlanModel';

class AddPlanResponseModel extends BaseModel {
  public plan: PlanModel;

  constructor(arg: any) {
    super(arg);
    this.plan = arg.plan;
  }
}

export default AddPlanResponseModel;
