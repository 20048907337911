













































































import Vue from 'vue';
import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb.vue';
import BaseText from '../../../../../../components/text/BaseText.vue';
import BaseIcon from '../../../../../../components/base-icon/BaseIcon.vue';
import EnterIcon from '../../../../../../assets/icons/applications/EnterIcon.vue';
import { ZidButton, ZidModal } from '@zidsa/ui';
import router from '../../../../../../router/index';
import { RoutingRouteEnum } from '../../../../../../router/routes.enum';
import { sendAppForRewview } from '../../../../api/steps/publish-app/publishApp';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { welcomePartner } from '@/api/top-level-apis/welcome-partner/welcomePartner';
import ExclamationIcon from '@/assets/icons/domains/partnership-details/ExclamationIcon.vue';
import ErrorModel from '@/api/models/ErrorModel';

const { useGetters } = createNamespacedHelpers('application');

export default defineComponent({
  components: {
    BreadCrumb,
    BaseText,
    BaseIcon,
    EnterIcon,
    ZidButton,
    ZidModal,
    ExclamationIcon,
  },
  setup() {
    const doesDevStoreExist = ref(false);
    const applicationID = ref('');
    const applicationURL = ref('');
    const isAppInstalled = ref(false);
    const { inProgressFullApp } = useGetters(['inProgressFullApp']);
    const hasCompletedPartnershipForm = ref(false);
    const showPartnershipMessage = ref(false);

    const togglePartnershipMessage = () => (showPartnershipMessage.value = !showPartnershipMessage.value);

    if (inProgressFullApp.value) {
      const {
        payload: { app, development, app_url, isAppInDevelopmentStore },
      } = inProgressFullApp.value;
      applicationID.value = app.id;
      isAppInstalled.value = isAppInDevelopmentStore;
      applicationURL.value = app_url;
      doesDevStoreExist.value = development !== null;
    }

    onBeforeMount(async () => {
      const response = await welcomePartner();
      if (response instanceof ErrorModel || !response.payload) return;
      hasCompletedPartnershipForm.value = response.payload.partnership_contract;
      showPartnershipMessage.value = !response.payload.partnership_contract;
    });

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.CreateApplication_PlansManagement }).catch(() => {
        //
      });
    };

    const onSendForReviewClicked = async () => {
      if (!applicationID.value) return;
      const response = await sendAppForRewview(applicationID.value);
      if (response.status !== 'success') {
        Vue.$toast.error('Failed to send request for review');
        return;
      }
      Vue.$toast.success('Application is sent for review successfully');
      router.push({ name: RoutingRouteEnum.Applications }).catch(() => {
        //
      });
    };

    return {
      applicationURL,
      doesDevStoreExist,
      hasCompletedPartnershipForm,
      showPartnershipMessage,
      isAppInstalled,
      togglePartnershipMessage,
      onBackClicked,
      onSendForReviewClicked,
    };
  },
});
