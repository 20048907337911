import { defineComponent } from '@vue/composition-api';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb.vue';
import { RoutingRouteEnum } from '../../../../../../router/routes.enum';
import BaseText from '../../../../../../components/text/BaseText.vue';
import {
  ZidIcon,
  ZidRadio,
  ZidButton,
  ZidModal,
  ZidInput,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTextarea,
  ZidLoader,
  ZidSwitch,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import BaseIcon from '../../../../../../components/base-icon/BaseIcon.vue';
import DeleteIcon from '../../../../../../assets/icons/applications/DeleteIcon.vue';
import EditIcon from '../../../../../../assets/icons/applications/EditIcon.vue';
import usePlanManagement from '../../../../helpers/hooks/plans-management/usePLanManagement';
import { PlanTypes } from '../../../../types/create-app/createApp.enum';
import TabMultiLanguage from '../../../../../../components/tab-multi-language/TabMultiLanguage';

export default defineComponent({
  components: {
    BreadCrumb,
    BaseText,
    ZidRadio,
    ZidButton,
    BaseIcon,
    DeleteIcon,
    EditIcon,
    ZidIcon,
    ZidModal,
    ZidInput,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidTextarea,
    ZidLoader,
    ZidSwitch,
    TabMultiLanguage,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    return {
      RoutingRouteEnum,
      PlanTypes,
      ...usePlanManagement(),
    };
  },
});
