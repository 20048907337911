import Vue from 'vue';
import { reactive, ref, watch, onBeforeUnmount, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import router from '../../../../../router/index';
import { RoutingRouteEnum } from '../../../../../router/routes.enum';
import { SubscribtionType } from '../../../types/create-app/createApp.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { setWebhookGroupDataToApi } from '../../../api/steps/webhook-management/webhookManagement';
import { WebhookGroup, WebhookType, WebhookResponse } from '../../../types/create-app/createApp.enum';
import { deleteWebhookGroup } from '../../../api/steps/webhook-management/webhookManagement';
import { getApplicationData } from '@/api/top-level-apis/application/application';
import ErrorModel from '@/api/models/ErrorModel';
import FullApplicationProgressResponseModel from '@/api/models/FullApplicationProgressResponseModel';

const { useGetters, useActions } = createNamespacedHelpers('application');

const useWebhooksManagement = () => {
  const { inProgressFullApp } = useGetters(['inProgressFullApp']);
  const { addStepCompleted } = useActions(['addStepCompleted']);

  const showAddWebhook = ref(false);
  const loadingAddOrEditWebhook = ref(false);
  const isLoadingWebhooks = ref(false);
  const showDeleteWebhookModal = ref(false);
  const isDeletingWebhookGroup = ref(false);
  const selectedWebhookGroupID = ref('');
  let applicationId: number | null = null;
  const webhooksOptions: Record<string, string[]> = reactive({ options: [] });
  let existingWebhookGroups: Record<string, WebhookResponse[]> | null = null;

  const createAppWebhooksState: SubscribtionType = reactive({
    id: -1,
    webhookSelection: [],
    targetURL: '',
    headers: '',
  });

  const webhookSubscribtionList: Record<string, WebhookGroup[]> = reactive({ subscirbtionList: [] });

  const isValidJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  //eslint-disable-next-line
  const URL_Regex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  const rules = {
    webhookSelection: {
      required,
      webhookSelection: helpers.withMessage('Please select application webhooks', required),
    },
    targetURL: {
      required,
      targetURL: helpers.withMessage('URL is not valid', (value: string) => URL_Regex.test(value)),
    },
    headers: {
      headers: helpers.withMessage(
        'JSON format is not valid',
        (value: string) => isValidJsonString(value) || value === '',
      ),
    },
  };

  const v$ = useVuelidate(rules, createAppWebhooksState);

  const toggleAddwebhook = () => (showAddWebhook.value = !showAddWebhook.value);
  const toggleDeleteWebhookModal = () => (showDeleteWebhookModal.value = !showDeleteWebhookModal.value);

  const updateCurrentSubscriptionList = () => {
    if (!existingWebhookGroups) return;
    for (const [key, value] of Object.entries(existingWebhookGroups)) {
      webhookSubscribtionList.subscirbtionList.push({
        group_id: key,
        webhooks: value,
      });
    }
  };

  onBeforeMount(async () => {
    if (inProgressFullApp.value) {
      const {
        payload: { app },
      } = inProgressFullApp.value;
      applicationId = app.id;
    }

    if (applicationId) {
      await loadAppWebhooks();
    }
  });

  const loadAppWebhooks = async () => {
    if (applicationId) {
      isLoadingWebhooks.value = true;
      const response = await getApplicationData(applicationId);

      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Could not load webhooks!');
        isLoadingWebhooks.value = false;
        router.push({ name: RoutingRouteEnum.CreateApplication_Publish });
        return;
      }
      const res: FullApplicationProgressResponseModel = response;

      webhooksOptions.options = res.payload?.webhooksAvailable || [];
      existingWebhookGroups = res.payload?.webhooks || null;
      if (existingWebhookGroups) {
        webhookSubscribtionList.subscirbtionList = [];
        updateCurrentSubscriptionList();
      }
      isLoadingWebhooks.value = false;
    }
  };

  const onWebhookGroupDelete = async (webhookGroupID: string) => {
    selectedWebhookGroupID.value = webhookGroupID;
    showDeleteWebhookModal.value = true;
  };

  const onDeleteConfirmationClicked = async () => {
    if (!applicationId || !selectedWebhookGroupID) {
      return;
    }
    isDeletingWebhookGroup.value = true;
    const response = await deleteWebhookGroup(selectedWebhookGroupID.value, applicationId);
    if (!response.deleted) {
      isDeletingWebhookGroup.value = false;
      Vue.$toast.error('Failed to delete webhook group');
      toggleDeleteWebhookModal();
      return;
    }

    await loadAppWebhooks();
    isDeletingWebhookGroup.value = false;
    selectedWebhookGroupID.value = '';
    Vue.$toast.success('Webhook group has been deleted');
    toggleDeleteWebhookModal();
  };

  const onSubscribeWebhookClicked = () => {
    if (!showAddWebhook.value) toggleAddwebhook();
  };

  const prepareWebhookGroupData = () => {
    const webhooks: WebhookType[] = [];
    let webhookGroupID = null;
    let selectedWebhookGroup: WebhookGroup | null = null;
    if (selectedWebhookGroupID.value) {
      webhookGroupID = selectedWebhookGroupID.value;
      selectedWebhookGroup =
        webhookSubscribtionList.subscirbtionList.find(
          (webhhook) => webhhook.group_id === selectedWebhookGroupID.value,
        ) || null;
    }
    createAppWebhooksState.webhookSelection.map((selection) => {
      webhooks.push({
        id: selectedWebhookGroup?.webhooks.find((webhook) => webhook.name === selection)?.id || null,
        name: selection,
        target_url: createAppWebhooksState.targetURL,
        headers: createAppWebhooksState.headers ? JSON.parse(createAppWebhooksState.headers) : null,
      });
    });

    const newWebhookGroup: WebhookGroup = {
      group_id: webhookGroupID,
      webhooks,
    };
    return newWebhookGroup;
  };

  const onSaveOrEditClicked = async () => {
    if (applicationId) {
      loadingAddOrEditWebhook.value = true;
      const newWebhookGroup = prepareWebhookGroupData();

      const response = await setWebhookGroupDataToApi(newWebhookGroup, applicationId);
      if (!response.webhooks) {
        loadingAddOrEditWebhook.value = false;
        if (selectedWebhookGroupID.value !== '') Vue.$toast.error('Failed to update webhook group');
        else Vue.$toast.error('Failed to Add new webhook group');
        return;
      }
      await loadAppWebhooks();
      if (selectedWebhookGroupID.value !== '') Vue.$toast.success('Updated webhook group successfully');
      else Vue.$toast.success('Added new webhook group successfully');
    }
    loadingAddOrEditWebhook.value = false;
    selectedWebhookGroupID.value = '';
    createAppWebhooksState.webhookSelection = [];
    createAppWebhooksState.targetURL = '';
    createAppWebhooksState.headers = '';
    v$.value.$reset();
    toggleAddwebhook();
  };

  const onContinueClicked = () => {
    addStepCompleted(RoutingRouteEnum.CreateApplication_PlansManagement);
    router.push({ name: RoutingRouteEnum.CreateApplication_PlansManagement }).catch(() => {
      //
    });
  };

  const onCancelClicked = async () => {
    createAppWebhooksState.webhookSelection = [];
    createAppWebhooksState.targetURL = '';
    createAppWebhooksState.headers = '';
    v$.value.$reset();
    toggleAddwebhook();
    await loadAppWebhooks();
  };

  const onWebhookGroupEdit = async (webhookGroupID: string) => {
    if (!showAddWebhook.value) toggleAddwebhook();
    if (webhookGroupID !== selectedWebhookGroupID.value) {
      selectedWebhookGroupID.value = webhookGroupID;
      createAppWebhooksState.webhookSelection = [];
      createAppWebhooksState.targetURL = '';
      createAppWebhooksState.headers = '';
      v$.value.$reset();
    }
    await loadAppWebhooks();
    const webhooks = webhookSubscribtionList.subscirbtionList.find(
      (webhookGroup: WebhookGroup) => webhookGroup.group_id === webhookGroupID,
    )?.webhooks;
    if (webhooks?.length) {
      createAppWebhooksState.webhookSelection = webhooks?.map((webhook) => webhook.name);
      createAppWebhooksState.targetURL = webhooks[0].target_url;
      createAppWebhooksState.headers = webhooks[0].headers ?? '{}';
    }
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: 'targetURL' | 'headers') => {
    v$.value[dataProperty].$touch();
    createAppWebhooksState[dataProperty] = changedValue.value;
  };

  const updateWebhookSelectionList = async (selectedList: string[]) => {
    createAppWebhooksState.webhookSelection = [...selectedList];
    let newAllowedWebhooksAfterSelection: string[] | null = null;
    if (webhooksOptions.options.length) {
      newAllowedWebhooksAfterSelection = webhooksOptions.options.filter(
        (webhook) => !createAppWebhooksState.webhookSelection.includes(webhook),
      );
    }
  };

  const onWebhookSelectionRemoved = async (removedWebhook: string) => {
    const newAllowedWebhooksAfterRemoving = [...webhooksOptions.options, removedWebhook];
    webhooksOptions.options = [...newAllowedWebhooksAfterRemoving];
  };

  return {
    createAppWebhooksState,
    webhooksOptions,
    webhookSubscribtionList,
    showAddWebhook,
    loadingAddOrEditWebhook,
    showDeleteWebhookModal,
    isDeletingWebhookGroup,
    selectedWebhookGroupID,
    v$,
    isLoadingWebhooks,
    onInputDataChange,
    onWebhookGroupDelete,
    onSubscribeWebhookClicked,
    onSaveOrEditClicked,
    onContinueClicked,
    onCancelClicked,
    onWebhookGroupEdit,
    toggleDeleteWebhookModal,
    onDeleteConfirmationClicked,
    updateWebhookSelectionList,
    onWebhookSelectionRemoved,
  };
};

export default useWebhooksManagement;
