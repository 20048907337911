<template>
  <div class="general-settings-step">
    <bread-crumb :disabledItemsList="disabledBreadCrumbList" />
    <div class="my-8 mx-6 p-8 rounded-lg shadow-md bg-white-pureWhite">
      <div class="flex flex-col">
        <form>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-6">
              <div class="flex flex-col">
                <base-text textStyle="h3" classes="text-primary-700 mb-3"> Your API keys: </base-text>
                <base-text textStyle="p3" classes="text-neutrals-black">
                  Use these keys as environment variables in your application, and keep them safe.
                </base-text>
              </div>
            </div>
            <div class="p-4 bg-white col-span-12 lg:col-span-6">
              <fieldset class="mb-3 md:mb-5">
                <label for="oauthEndPoint">
                  <base-text textStyle="h5" classes="flex mb-1"> OAuth 2.0 End-Point </base-text>
                </label>
                <zid-input
                  :disabled="true"
                  value="https://oauth.zid.sa"
                  class="create-app-zid-input"
                  id="oauthEndPoint"
                  name="OAuth End-Point"
                  placeholder="https://oauth.zid.sa"
                >
                </zid-input>
              </fieldset>
              <fieldset class="mb-3 md:mb-5">
                <label for="clientID">
                  <base-text textStyle="h5" classes="flex mb-1"> Client ID </base-text>
                </label>
                <zid-input
                  :value="v$.clientID.$model"
                  :disabled="true"
                  class="create-app-zid-input"
                  id="clientID"
                  name="Client ID"
                >
                </zid-input>
              </fieldset>
              <fieldset class="mb-3 md:mb-5">
                <label for="clientSecret">
                  <base-text textStyle="h5" classes="flex mb-1"> Client Secret </base-text>
                </label>
                <zid-input
                  :disabled="true"
                  :value="v$.clientSecret.$model"
                  class="create-app-zid-input"
                  id="clientSecret"
                  name="Client Secret"
                >
                </zid-input>
              </fieldset>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="flex flex-col">
                <base-text textStyle="h3" classes="text-primary-700 flex mb-3">
                  Application Scopes:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
                <base-text textStyle="p3" classes="text-neutrals-black">
                  Select the scopes that your application is going to use.
                </base-text>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div v-if="appScopesList.list.length > 0" class="p-4 bg-white grid grid-cols-12 gap-4">
                <app-scope
                  v-for="scope in appScopesList.list"
                  :key="scope.id"
                  :sectionName="scope.name.en"
                  :scopes="scope.scopes"
                  @readRadioButtonSelected="onReadRadioButtonSelected"
                  @readRadioDoubleSelect="onReadRadioDoubleSelect"
                  @readWriteRadioButtonSelected="onReadWriteRadioButtonSelected"
                  @readWriteRadioDoubleSelect="onReadWriteRadioDoubleSelect"
                />
              </div>
              <div v-else class="flex justify-center p-12 bg-white">
                <zid-loader size="large" />
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="flex flex-col">
                <base-text textStyle="h3" classes="text-primary-700 flex mb-3">
                  Description:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
                <base-text textStyle="p3" classes="text-neutrals-black">
                  Please give a short description for you selections.
                </base-text>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="general-settings-text-area-container p-4 py-6 bg-white">
                <zid-textarea
                  :value="v$.description.$model"
                  :invalid="v$.description.$dirty && v$.description.$invalid"
                  :errorMessage="v$.description.description.$message"
                  name="Description"
                  class="general-settings-text-area w-full pb-2"
                  @change="onInputDataChange($event, 'description')"
                />
                <div class="w-full flex justify-end pb-8 px-2">
                  <base-text textStyle="p4" classes="text-primary"> {{ v$.description.$model.length }}/200 </base-text>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-6">
            <div class="w-full lg:w-2/6 flex flex-col lg:flex-row">
              <zid-button type="neutral" class="w-full lg:w-3/6 me-0 lg:me-4" @click.prevent="onBackClicked">
                <base-text textStyle="h4" classes="p-2 text-neutrals-black"> Back </base-text>
              </zid-button>
              <zid-button
                :disabled="!selectedScopes.list.length || v$.$invalid"
                class="w-full lg:w-3/6 mt-3 lg:mt-0"
                @click.prevent="onSubmit"
              >
                <base-text textStyle="h4" classes="p-2 text-white-pureWhite">
                  {{ `Save & Continue` }}
                </base-text>
              </zid-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api';
import { ZidInput, ZidCheckbox, ZidIcon, ZidButton, ZidTextarea, ZidLoader } from '@zidsa/ui';
import BaseText from '../../../../../../components/text/BaseText.vue';
import useCreateAppGeneralSettings from '../../../../helpers/hooks/create-app-general-settings/useCreateAppGeneralSettings';
import AppScope from '../../../../components/create-app/AppScope.vue';
import router from '../../../../../../router/index';
import { RoutingRouteEnum } from '../../../../../../router/routes.enum';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { setGeneralSettingsDataToApi } from '../../../../api/steps/general-settings/generalSettings';

const { useActions, useGetters } = createNamespacedHelpers('application'); // module name

export default defineComponent({
  components: {
    ZidInput,
    BaseText,
    ZidCheckbox,
    ZidIcon,
    ZidButton,
    AppScope,
    ZidTextarea,
    BreadCrumb,
    ZidLoader,
  },
  setup(_, context) {
    const disabledBreadCrumbList = reactive([
      RoutingRouteEnum.CreateApplication_AppDetails,
      RoutingRouteEnum.CreateApplication_WebhookManagement,
      RoutingRouteEnum.CreateApplication_PlansManagement,
      RoutingRouteEnum.CreateApplication_Publish,
    ]);

    const {
      createAppGeneralSettings,
      v$,
      appScopesList,
      selectedScopes,
      onReadRadioButtonSelected,
      onReadRadioDoubleSelect,
      onReadWriteRadioButtonSelected,
      onReadWriteRadioDoubleSelect,
      onInputDataChange,
    } = useCreateAppGeneralSettings();

    const { addStepCompleted, setInProgressFullApplication } = useActions([
      'addStepCompleted',
      'setInProgressFullApplication',
    ]);
    let applicationId = null;
    const { inProgressFullApp } = useGetters(['inProgressFullApp']);
    if (inProgressFullApp.value) {
      const {
        payload: {
          app: { id },
        },
      } = inProgressFullApp.value;
      applicationId = id;
    }

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.CreateApplication }).catch(() => {
        //
      });
    };

    const onSubmit = async () => {
      const response = await setGeneralSettingsDataToApi(
        { scopes: selectedScopes.list, scopes_note: createAppGeneralSettings.description },
        applicationId,
      );
      if (response.status === 'success') {
        let payloadCopy = { ...inProgressFullApp.value.payload };
        let modifiedScopeGroupes = JSON.parse(JSON.stringify(payloadCopy.scopeGroups));
        modifiedScopeGroupes = modifiedScopeGroupes.map((scopeItem) => {
          const readScope = scopeItem.scopes.find((scope) => scope.type === 0);
          const readWriteScope = scopeItem.scopes.find((scope) => scope.type === 1);
          if (readScope) {
            if (selectedScopes.list.includes(readScope.id)) {
              readScope.is_granted = true;
              if (readWriteScope) readWriteScope.is_granted = false;
            } else readScope.is_granted = false;
          }
          if (readWriteScope) {
            if (selectedScopes.list.includes(readWriteScope.id)) {
              readWriteScope.is_granted = true;
              if (readScope) readScope.is_granted = false;
            } else readWriteScope.is_granted = false;
          }
          return scopeItem;
        });
        const newFullApp = {
          ...inProgressFullApp.value,
          payload: {
            ...inProgressFullApp.value.payload,
            app: { ...inProgressFullApp.value.payload.app, scopes_note: createAppGeneralSettings.description },
            scopeGroups: [...modifiedScopeGroupes],
          },
        };
        setInProgressFullApplication(newFullApp);
        addStepCompleted(RoutingRouteEnum.CreateApplication_AppDetails);
        router
          .push({
            name: RoutingRouteEnum.CreateApplication_AppDetails,
          })
          .catch(() => {
            /**/
          });
      }
    };

    return {
      disabledBreadCrumbList,
      createAppGeneralSettings,
      appScopesList,
      selectedScopes,
      v$,
      onBackClicked,
      onSubmit,
      onReadRadioButtonSelected,
      onReadRadioDoubleSelect,
      onReadWriteRadioButtonSelected,
      onReadWriteRadioDoubleSelect,
      onInputDataChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.general-settings-step {
  .general-settings-text-area-container {
    height: 200px;
    .general-settings-text-area {
      height: 100%;
    }
    .general-settings-text-area::v-deep textarea {
      height: 100%;
    }
  }
}
</style>
