import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import { ZidInput, ZidButton, ZidTextarea, ZidFileSelector, ZidLoader, ZidSwitch, ZidAlert } from '@zidsa/ui';
import BaseText from '../../../../../../components/text/BaseText.vue';
import VueEasymde from 'vue-easymde';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb.vue';
import { RoutingRouteEnum } from '../../../../../../router/routes.enum';
import useCreateAppDetails from '../../../../helpers/hooks/create-app-details/useCreateAppDetails';
import router from '../../../../../../router/index';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import TabMultiLanguage from '../../../../../../components/tab-multi-language/TabMultiLanguage.vue';
import { setApplicationDetailsToApi } from '../../../../api/steps/application-details/applicationDetails';
import ErrorModel from '../../../../../../api/models/ErrorModel';
import InstallAppOnDevelopmentStore from '@/domains/development-store/components/install-app-on-development-store/InstallAppOnDevelopmentStore.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import GuidelineIcon from '@/assets/icons/applications/GuidelineIcon.vue';
import { EMBEDDED_APP_DOCS } from '@/helpers/constantLinks.ts';

const { useActions, useGetters } = createNamespacedHelpers('application');

export default defineComponent({
  components: {
    ZidInput,
    ZidButton,
    BaseText,
    BreadCrumb,
    VueEasymde,
    ZidTextarea,
    TabMultiLanguage,
    ZidFileSelector,
    ZidLoader,
    InstallAppOnDevelopmentStore,
    ZidSwitch,
    ZidAlert,
    BaseIcon,
    GuidelineIcon,
  },
  setup() {
    let {
      inProgressFullApp,
      createAppDetailsState,
      v$,
      iconFileNameFromGlobal,
      isIconSpecsValid,
      isSaveDisabled,
      applicationId,
      showMessageForDescriptionArEnField,
      showMessageDeveloperNameField,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      originalArabicScreenshots,
      originalEnglishScreenshots,
      areArabicFiles,
      mdAppLink,
      hasEmbeddedAppsScope,
      onIconSelection,
      onInputDataChange,
      onArabicScreenshotsChange,
      onEnglishScreenshotsChange,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsRemove,
      toggleIsEmbeddedOption,
    } = useCreateAppDetails();

    const descriptionMarkdownConfigs = {
      toolbar: [
        'bold',
        'italic',
        'strikethrough',
        '|',
        'heading',
        '|',
        'heading-smaller',
        '|',
        'heading-bigger',
        '|',
        'quote',
        'code',
        '|',
        'unordered-list',
        'ordered-list',
        '|',
        'link',
        '|',
        'image',
        '|',
        'clean-block',
        '|',
        'guide',
      ],
      spellChecker: false,
      status: [],
    };
    const { addStepCompleted, setInProgressFullApplication } = useActions([
      'addStepCompleted',
      'setInProgressFullApplication',
    ]);

    const { appType } = useGetters(['appType']);

    const redirectURLDocsLink = ref('https://docs.zid.sa/docs/authorization-migration/2cgihlrgqoz36-authorization');
    const callbackURLDocsLink = ref('https://docs.zid.sa/docs/authorization-migration/2cgihlrgqoz36-authorization');

    const onBackClicked = () => {
      if (appType.value === 'normal') {
        router
          .push({
            name: RoutingRouteEnum.CreateApplication_GeneralSettings_Normal,
          })
          .catch(() => {
            //
          });
      } else if (appType.value === 'javascript') {
        router
          .push({
            name: RoutingRouteEnum.CreateApplication_GeneralSettings_Javascript,
          })
          .catch(() => {
            //
          });
      } else {
        router.push({ name: RoutingRouteEnum.CreateApplication }).catch(() => {
          //
        });
      }
    };

    const onSubmit = async () => {
      const appDetailsData = {
        appURL: createAppDetailsState.appURL,
        redirectURL: createAppDetailsState.redirectURL,
        callbackURL: createAppDetailsState.callbackURL,
        isEmbedded: createAppDetailsState.isEmbedded,
        description: createAppDetailsState.description,
        descriptionArabic: createAppDetailsState.descriptionArabic,
        shortDesciption: createAppDetailsState.shortDesciption,
        shortDesciptionArabic: createAppDetailsState.shortDesciptionArabic,
        developerName: createAppDetailsState.developerName,
        developerNameArabic: createAppDetailsState.developerNameArabic,
        videoURL: createAppDetailsState.videoURL,
        appScreenshotsAr: [...originalArabicScreenshots.list],
        appScreenshotsEn: [...originalEnglishScreenshots.list],
        iconFile: createAppDetailsState.iconFile instanceof File ? createAppDetailsState.iconFile : null,
        storeLine: createAppDetailsState.storeLine,
      };

      const response = await setApplicationDetailsToApi(appDetailsData, applicationId);

      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to update application details');
        return;
      }

      const { payload } = inProgressFullApp.value;
      if (!payload) {
        Vue.$toast.success('Updated application details successfully!');
        router
          .push({
            name: RoutingRouteEnum.Applications,
          })
          .catch(() => {
            //
          });
        return;
      }
      const { app } = payload;
      const modifiedApp = { ...app };
      modifiedApp.url = appDetailsData.appURL;
      modifiedApp.installation_url = appDetailsData.redirectURL;
      modifiedApp.redirect_uri = appDetailsData.callbackURL;
      modifiedApp.is_embedded = appDetailsData.isEmbedded;
      modifiedApp.description = appDetailsData.description;
      modifiedApp.description_ar = appDetailsData.descriptionArabic;
      modifiedApp.short_description = appDetailsData.shortDesciption;
      modifiedApp.short_description_ar = appDetailsData.shortDesciptionArabic;
      modifiedApp.developer_name_en = appDetailsData.developerName;
      modifiedApp.developer_name_ar = appDetailsData.developerNameArabic;
      modifiedApp.video = appDetailsData.videoURL;
      modifiedApp.icon = createAppDetailsState.iconFile;
      modifiedApp.screen_shots_ar = response.payload.screen_shots_ar || '';
      modifiedApp.screen_shots_en = response.payload.screen_shots_en || '';
      const newFullApp = {
        ...inProgressFullApp.value,
        payload: {
          ...inProgressFullApp.value.payload,
          app: { ...modifiedApp },
        },
      };
      setInProgressFullApplication(newFullApp);
      addStepCompleted(RoutingRouteEnum.CreateApplication_WebhookManagement);
      addStepCompleted(RoutingRouteEnum.CreateApplication_PlansManagement);
      addStepCompleted(RoutingRouteEnum.CreateApplication_Publish);
      Vue.$toast.success('Updated application details successfully!');
      router
        .push({
          name: RoutingRouteEnum.CreateApplication_WebhookManagement,
        })
        .catch(() => {
          //
        });
    };

    const onRemoveIconClicked = () => {
      createAppDetailsState.iconFile = null;
      iconFileNameFromGlobal.value = '';
    };

    return {
      descriptionMarkdownConfigs,
      RoutingRouteEnum,
      appType,
      createAppDetailsState,
      v$,
      isSaveDisabled,
      iconFileNameFromGlobal,
      isIconSpecsValid,
      showMessageForDescriptionArEnField,
      showMessageDeveloperNameField,
      redirectURLDocsLink,
      callbackURLDocsLink,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      areArabicFiles,
      applicationId,
      mdAppLink,
      hasEmbeddedAppsScope,
      EMBEDDED_APP_DOCS,
      onRemoveIconClicked,
      onIconSelection,
      onInputDataChange,
      onSubmit,
      onBackClicked,
      onArabicScreenshotsChange,
      onEnglishScreenshotsChange,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsRemove,
      toggleIsEmbeddedOption,
    };
  },
});
