import { reactive, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { NormalAppInterface } from '../../../../../store/modules/application/applicationState.type';
import { helpers, required, minLength } from '@vuelidate/validators';

const { useGetters } = createNamespacedHelpers('application'); // module name

const useCreateAppGeneralSettings = () => {
  const { inProgressFullApp } = useGetters(['inProgressFullApp']);

  const appScopesList = reactive<Record<string, Record<string | number, any>[]>>({ list: [] });
  let clientID = '';
  let clientSecret = '';
  let scopes_description = '';
  if (inProgressFullApp.value) {
    const { payload } = inProgressFullApp.value;
    const { client_id, client_secret } = payload.app;
    clientID = `${client_id}`;
    clientSecret = client_secret;
    scopes_description = payload.app.scopes_note ?? '';
    if (payload) appScopesList.list = payload.scopeGroups;
  }

  const createAppGeneralSettings = reactive<NormalAppInterface>({
    clientID: clientID ?? '',
    clientSecret: clientSecret ?? '',
    description: scopes_description ?? '',
  });

  const rules = {
    clientID: {},
    clientSecret: {},
    description: {
      required,
      description: helpers.withMessage('Minimum size: 200 characters ', minLength(200)),
    },
  };

  const v$ = useVuelidate(rules, createAppGeneralSettings);

  const selectedScopes = reactive({
    list: appScopesList.list
      .flatMap((item) => item.scopes)
      .filter((scope) => scope.is_granted)
      .map((scope) => scope.id),
  });

  watch(
    () => inProgressFullApp,
    (inPorgressFullAppValue) => {
      if (inPorgressFullAppValue.value) {
        const { payload } = inPorgressFullAppValue.value;
        const { client_id, client_secret } = payload.app;
        createAppGeneralSettings.clientID = `${client_id}`;
        createAppGeneralSettings.clientSecret = client_secret;
        if (payload) appScopesList.list = payload.scopeGroups;
      }
    },
    { deep: true },
  );

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: 'description') => {
    v$.value[dataProperty].$touch();
    createAppGeneralSettings[dataProperty] = changedValue.value;
  };

  const onReadRadioButtonSelected = (changedValue: Record<string, string>, writeId: number) => {
    if (!selectedScopes.list.includes(parseInt(changedValue.value)))
      selectedScopes.list.push(parseInt(changedValue.value));
    // remoeve readWrite if selected
    if (writeId !== -1 && selectedScopes.list.includes(writeId))
      selectedScopes.list.splice(
        selectedScopes.list.findIndex((id) => writeId === id),
        1,
      );
  };

  const onReadRadioDoubleSelect = (readId: string) => {
    if (selectedScopes.list.includes(parseInt(readId))) {
      selectedScopes.list = [...selectedScopes.list.filter((scopeID) => scopeID !== parseInt(readId))];
    }
  };

  const onReadWriteRadioButtonSelected = (readId: number, writeId: number) => {
    if (readId !== 0) {
      if (!selectedScopes.list.includes(readId)) selectedScopes.list.push(readId);
      if (!selectedScopes.list.includes(writeId)) selectedScopes.list.push(writeId);
    } else {
      if (!selectedScopes.list.includes(writeId)) selectedScopes.list.push(writeId);
    }
  };

  const onReadWriteRadioDoubleSelect = (readId: number, writeId: number) => {
    if (readId !== 0)
      selectedScopes.list = [...selectedScopes.list.filter((scopeID) => scopeID !== readId && scopeID !== writeId)];
    else selectedScopes.list = [...selectedScopes.list.filter((scopeID) => scopeID !== writeId)];
  };

  return {
    createAppGeneralSettings,
    appScopesList,
    selectedScopes,
    v$,
    onReadRadioButtonSelected,
    onReadRadioDoubleSelect,
    onReadWriteRadioButtonSelected,
    onReadWriteRadioDoubleSelect,
    onInputDataChange,
  };
};

export default useCreateAppGeneralSettings;
