import httpClient from '../../../../../api/httpClient';
import { PlanInfoType } from '../../../types/create-app/createApp.enum';
import AddPlanResponseModel from '../../../models/AddPlanResponseModel';
import DeletePlanResponseModel from '../../../models/DeletePlanResponseModel';

const ENDPOINT_URL = '/market';

export const sendPlanToAPI = (newPlanInfo: Record<string, PlanInfoType>, applicationID: number | string) => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/${applicationID}/plan`, newPlanInfo)
    .then((response) => new AddPlanResponseModel(response))
    .catch((error) => error);
};

export const deletePlan = (purchasableID: string, applicationID: number | string) => {
  return httpClient
    .delete(`${ENDPOINT_URL}/delete/${applicationID}/plan`, {
      data: {
        pricingPlans: {
          purchasable_id: purchasableID,
        },
      },
    })
    .then((response) => new DeletePlanResponseModel(response))
    .catch((error) => error);
};
