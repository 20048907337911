import Vue from 'vue';
import { computed, reactive, ref, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, minLength, maxLength, required, requiredIf } from '@vuelidate/validators';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { RoutingRouteEnum } from '../../../../../router/routes.enum';
import router from '../../../../../router/index';
import {
  PlanTypes,
  PlanType,
  PlanInfoType,
  PlanOfferType,
  PlanOfferTypeLabelsEnum,
  PlanOfferTypeValuesEnum,
  PlansInvoicePeriodEnum,
  planStatePropertiesEnum,
  PlanStateSelectPropertiesEnum,
} from '../../../types/create-app/createApp.enum';
import PlanModel from '../../../models/PlanModel';
import { sendPlanToAPI, deletePlan } from '../../../api/steps/plans-management/plansManagement';
import { getApplicationData } from '@/api/top-level-apis/application/application';
import ErrorModel from '@/api/models/ErrorModel';

const { useGetters, useActions } = createNamespacedHelpers('application');

const usePlanManagement = () => {
  const { addStepCompleted } = useActions(['addStepCompleted']);

  const { inProgressFullApp } = useGetters(['inProgressFullApp']);

  const planType = ref('');
  const showShowAddPlanModal = ref(false);
  const isAddPlanDisabled = ref(false);
  const showDeletePlanModal = ref(false);
  const loadingDeletePlan = ref(false);
  const isOfferApplied = ref(false);
  const isLoadingPlansPage = ref(false);
  const selectedPlanOfferType = ref(PlanOfferTypeValuesEnum.BuyXGetYOffer);
  const currentlySelectedPlanID = ref(0);
  const currentlySelectedPurchasableID = ref('');
  const currentlySelectedPlanOneTimeFeePurchasableID = ref('');
  const currentlySelectedPlanOfferID = ref(0);
  const currentlySelectedPlanOfferPurchasableID = ref('');
  const plansList: Record<string, PlanModel[]> = reactive({ plansList: [] });
  let applicationId: number | null = null;

  const toggleDeletePlanModal = () => (showDeletePlanModal.value = !showDeletePlanModal.value);
  const toggleShowAddPlanModal = () => (showShowAddPlanModal.value = !showShowAddPlanModal.value);
  const togglePlanOffer = () => {
    isOfferApplied.value = !isOfferApplied.value;
    currentlySelectedPlanOfferID.value = 0;
    currentlySelectedPlanOfferPurchasableID.value = '';
    createAppPlansManagement.planOfferDuration = { label: '', value: '' };
  };

  onBeforeMount(async () => {
    if (inProgressFullApp.value) {
      const {
        payload: { app },
      } = inProgressFullApp.value;
      applicationId = app.id;
    }

    if (applicationId) {
      await loadAppPlans();
    }
  });

  const loadAppPlans = async () => {
    if (applicationId) {
      isLoadingPlansPage.value = true;
      const response = await getApplicationData(applicationId);

      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Could not load plans!');
        isLoadingPlansPage.value = false;
        router.push({ name: RoutingRouteEnum.CreateApplication_Publish });
        return;
      }

      plansList.plansList = [...response.payload.plans];
      isAddPlanDisabled.value = plansList.plansList.length >= 4;
      planType.value = plansList.plansList.length ? PlanTypes.paid : PlanTypes.free;
      isLoadingPlansPage.value = false;
    }
  };

  const createAppPlansManagement: PlanType = reactive({
    id: -1,
    planName: '',
    planNameArabic: '',
    planDuration: {
      label: '',
      value: '',
    },
    planOfferDuration: {
      label: '',
      value: '',
    },
    planDiscountOffer: '',
    planDescription: '',
    pricePlan: '',
    trialDays: {
      label: '',
      value: '',
    },
    hasOneTimeFees: false,
    oneTimeFees: '',
    planFeatures: '',
  });

  const trialDaysList = [];

  for (let i = 0; i < 91; i++) {
    trialDaysList.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const validatePlanFeaturesEntry = () => {
    const lines = createAppPlansManagement.planFeatures.split('\n');
    if (lines.length > 5 || lines.some((line) => line.length > 50) || createAppPlansManagement.planFeatures === '')
      return false;
    else return true;
  };

  const rules = {
    planName: {
      required,
      planName: helpers.withMessage('Plan name is required in both English and Arabic', (value: string) => {
        return /^[A-Za-z0-9 _]*[A-Za-z]+[A-Za-z0-9 _]*$/.test(value);
      }),
    },
    planNameArabic: {
      required,
      planNameArabic: helpers.withMessage('Plan name is required in both Arabic and English', (value: string) => {
        return /^[\u0621-\u064A0-9 ]+$/.test(value);
      }),
    },
    planDuration: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    planOfferDuration: {
      label: {
        required: helpers.withMessage(
          'Select a valid duration for offer',
          requiredIf(
            () => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer,
          ),
        ),
      },
      value: {
        required: helpers.withMessage(
          'Select a valid duration for offer',
          requiredIf(
            () => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer,
          ),
        ),
      },
    },
    planDiscountOffer: {
      required: helpers.withMessage(
        'Provide a valid discount offer for plan',
        requiredIf(() => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.DiscountOffer),
      ),
      planDiscountOffer: helpers.withMessage('Minimum discount: 1%, Maximum discount: 60%', (value: string) => {
        return (
          (parseFloat(createAppPlansManagement.planDiscountOffer) >= 1 &&
            parseFloat(createAppPlansManagement.planDiscountOffer) <= 60) ||
          value === ''
        );
      }),
    },
    planDescription: {
      required,
      planDescription: helpers.withMessage('Minimum length: 50 charachters, Maximum length: 300 characters', required),
      maxLength: maxLength(300),
      minLength: minLength(50),
    },
    pricePlan: {
      pricePlan: helpers.withMessage('Minimum price: 1 SAR, Maximum price: 120000 SAR', () => {
        return (
          parseFloat(createAppPlansManagement.pricePlan) >= 1 &&
          parseFloat(createAppPlansManagement.pricePlan) <= 120000
        );
      }),
    },
    trialDays: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    hasOneTimeFees: {},
    oneTimeFees: {
      required: helpers.withMessage(
        'Please enter the one time fees in SAR',
        requiredIf(() => createAppPlansManagement.hasOneTimeFees),
      ),
      valid: helpers.withMessage('Please enter a valid fee value', () => {
        return (
          (createAppPlansManagement.hasOneTimeFees && parseFloat(createAppPlansManagement.oneTimeFees) > 0) ||
          !createAppPlansManagement.hasOneTimeFees
        );
      }),
    },
    planFeatures: {
      planFeatures: helpers.withMessage(
        'Only maximum of 5 lines allowed with 50 characters in each line.',
        validatePlanFeaturesEntry,
      ),
    },
  };

  const v$ = useVuelidate(rules, createAppPlansManagement);

  const durationsList = [
    { label: 'Free', value: PlanTypes.free },
    { label: '1 Month', value: '30' },
    { label: '2 Months', value: '60' },
    { label: '3 Months', value: '90' },
    { label: '4 Months', value: '120' },
    { label: '5 Months', value: '150' },
    { label: '6 Months', value: '180' },
    { label: '7 Months', value: '210' },
    { label: '8 Months', value: '240' },
    { label: '9 Months', value: '270' },
    { label: '10 Months', value: '300' },
    { label: '11 Months', value: '330' },
    { label: 'Yearly', value: '365' },
  ];

  const offerableDurationsList = computed(() => {
    return durationsList
      .filter((duration) => Number(duration.value) <= Number(createAppPlansManagement.planDuration.value))
      .slice(1);
  });

  const isAddOrEditPlanModalButtonDisabled = computed((): boolean => {
    if (createAppPlansManagement.planDuration.value === PlanTypes.free) {
      return (
        v$.value.planName.$invalid ||
        v$.value.planNameArabic.$invalid ||
        v$.value.planDuration.$invalid ||
        v$.value.planDescription.$invalid ||
        v$.value.planFeatures.$invalid
      );
    } else return v$.value.$invalid;
  });

  const resetData = () => {
    currentlySelectedPlanID.value = 0;
    currentlySelectedPurchasableID.value = '';
    currentlySelectedPlanOfferID.value = 0;
    currentlySelectedPlanOfferPurchasableID.value = '';
    currentlySelectedPlanOneTimeFeePurchasableID.value = '';
    isOfferApplied.value = false;
    (createAppPlansManagement.planName = ''),
      (createAppPlansManagement.planNameArabic = ''),
      (createAppPlansManagement.planDuration = {
        label: '',
        value: '',
      });
    createAppPlansManagement.planDescription = '';
    createAppPlansManagement.pricePlan = '';
    createAppPlansManagement.trialDays = {
      label: '',
      value: '',
    };
    createAppPlansManagement.hasOneTimeFees = false;
    createAppPlansManagement.oneTimeFees = '';
    createAppPlansManagement.planFeatures = '';
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: planStatePropertiesEnum) => {
    v$.value[dataProperty].$touch();
    createAppPlansManagement[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: PlanStateSelectPropertiesEnum) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    createAppPlansManagement[property].label = selectedValue.label;
    createAppPlansManagement[property].value = selectedValue.value;
    if (property === PlanStateSelectPropertiesEnum.PlanDuration && selectedValue.value === PlanTypes.free) {
      createAppPlansManagement.pricePlan = '0';
      createAppPlansManagement.trialDays = { label: '0', value: '0' };
      // remove offer
      isOfferApplied.value = false;
      createAppPlansManagement.planOfferDuration = { label: '', value: '' };
    }
  };

  const onPlanRadioClick = (plan: Record<string, string>) => (planType.value = plan.value);
  const onPlanOfferRadioClick = (plan: Record<string, PlanOfferTypeValuesEnum>) =>
    (selectedPlanOfferType.value = plan.value);

  const showDetailsSection = computed(() => {
    return (planType.value === PlanTypes.paid && plansList.plansList.length === 0) || planType.value === PlanTypes.free;
  });

  const onBackClicked = () => {
    router.push({ name: RoutingRouteEnum.CreateApplication_WebhookManagement }).catch(() => {
      //
    });
  };

  const onSaveFreePlan = async () => {
    if (plansList.plansList.length && applicationId) {
      let isDeletionFailure = false;
      plansList.plansList.forEach(async (plan) => {
        const response = await deletePlan(plan.purchasable_id, `${applicationId}`);
        if (!response.deleted) {
          isDeletionFailure = true;
          return;
        }
      });
      if (isDeletionFailure) Vue.$toast.error('Could not save free plan');
      else {
        await loadAppPlans();
        Vue.$toast.success('Free plan has been saved');
      }
    }
    addStepCompleted(RoutingRouteEnum.CreateApplication_Publish);
    router.push({ name: RoutingRouteEnum.CreateApplication_Publish }).catch(() => {
      //
    });
  };

  const onAddPlanClicked = () => {
    toggleShowAddPlanModal();
  };

  const onPlanDelete = (planPurchasableID: string) => {
    currentlySelectedPurchasableID.value = planPurchasableID;
    toggleDeletePlanModal();
  };

  const onDeleteConfirmationClicked = async () => {
    if (applicationId) {
      loadingDeletePlan.value = true;
      const response = await deletePlan(currentlySelectedPurchasableID.value, applicationId);
      if (!response.deleted) {
        loadingDeletePlan.value = false;
        currentlySelectedPurchasableID.value = '';
        Vue.$toast.error('Failed to delete plan');
        resetData();
        v$.value.$reset();
        toggleDeletePlanModal();
        return;
      }

      await loadAppPlans();
      loadingDeletePlan.value = false;
      currentlySelectedPurchasableID.value = '';
      Vue.$toast.success('Plan has been deleted');
      resetData();
      v$.value.$reset();
      toggleDeletePlanModal();
    }
  };

  const onPlanEdit = (planPurchasableID: string, planID: number) => {
    if (!showShowAddPlanModal.value) toggleShowAddPlanModal();
    if (currentlySelectedPurchasableID.value !== planPurchasableID || currentlySelectedPlanID.value !== planID) {
      resetData();
      v$.value.$reset();
    }
    currentlySelectedPlanID.value = planID;
    currentlySelectedPurchasableID.value = planPurchasableID;

    const selectedPlan = plansList.plansList.find((plan) => plan.id === planID);
    currentlySelectedPlanOfferID.value = selectedPlan?.offer?.id ?? 0;
    currentlySelectedPlanOfferPurchasableID.value = selectedPlan?.offer?.offer_purchasable_id ?? '';

    const selectedPlanDuration = () => {
      if (selectedPlan?.price === 0) return { label: 'Free', value: PlanTypes.free };
      else if (selectedPlan && selectedPlan.invoice_period % PlansInvoicePeriodEnum.monthly == 0)
        return {
          label: new String(selectedPlan.invoice_period / PlansInvoicePeriodEnum.monthly).concat(' Months'),
          value: selectedPlan.invoice_period.toString(),
        };
      else if (selectedPlan && selectedPlan.invoice_period === PlansInvoicePeriodEnum.yearly)
        return { label: 'Yearly', value: selectedPlan.invoice_period.toString() };
    };
    createAppPlansManagement.planName = selectedPlan?.name_en || '';
    createAppPlansManagement.planNameArabic = selectedPlan?.name_ar || '';
    createAppPlansManagement.planDuration = selectedPlanDuration() || { label: '', value: '' };
    isOfferApplied.value = !!selectedPlan?.offer?.offer_period;
    selectedPlanOfferType.value = selectedPlan?.plan_discount
      ? PlanOfferTypeValuesEnum.DiscountOffer
      : PlanOfferTypeValuesEnum.BuyXGetYOffer;
    createAppPlansManagement.planDiscountOffer = `${selectedPlan?.plan_discount}` || '';
    createAppPlansManagement.planOfferDuration = {
      label: durationsList.find((duration) => duration.value == selectedPlan?.offer?.offer_period)?.label ?? '',
      value: selectedPlan?.offer?.offer_period ?? '',
    };
    createAppPlansManagement.planDescription = selectedPlan?.description || '';
    createAppPlansManagement.pricePlan = `${selectedPlan?.price}` || '';
    createAppPlansManagement.trialDays = {
      label: `${selectedPlan?.trial_period}`,
      value: `${selectedPlan?.trial_period}`,
    };
    createAppPlansManagement.planFeatures = selectedPlan?.features || '';
    currentlySelectedPlanOneTimeFeePurchasableID.value = selectedPlan?.one_time_fees_purchasable_id || '';
    createAppPlansManagement.hasOneTimeFees = selectedPlan?.has_one_time_fees || false;
    createAppPlansManagement.oneTimeFees = `${selectedPlan?.one_time_fees}` || '';
    v$.value.$validate();
  };

  const onSavePlansAndContinue = () => {
    if (showShowAddPlanModal) toggleShowAddPlanModal();
    resetData();
    v$.value.$reset();
    addStepCompleted(RoutingRouteEnum.CreateApplication_Publish);
    router.push({ name: RoutingRouteEnum.CreateApplication_Publish }).catch(() => {
      //
    });
  };

  const onAddOrEditPlanClicked = async () => {
    if (applicationId) {
      const featureLines = createAppPlansManagement.planFeatures.split('\n');
      const oneLineFeaturesContainer = featureLines.join(' \n ');
      const planInfo: Record<string, PlanInfoType> = {
        pricingPlans: {
          id: currentlySelectedPlanID.value !== 0 ? currentlySelectedPlanID.value : null,
          purchasable_id: currentlySelectedPurchasableID.value !== '' ? currentlySelectedPurchasableID.value : null,
          name_en: createAppPlansManagement.planName,
          name_ar: createAppPlansManagement.planNameArabic,
          description: createAppPlansManagement.planDescription,
          features: oneLineFeaturesContainer,
          price: `${createAppPlansManagement.pricePlan}`,
          duration: createAppPlansManagement.planDuration.value,
          currency: 'SAR',
          trial_period: Number(createAppPlansManagement.trialDays.value),
          one_time_fees_purchasable_id: currentlySelectedPlanOneTimeFeePurchasableID.value || null,
          has_one_time_fees:
            createAppPlansManagement.planDuration.value !== PlanTypes.free && createAppPlansManagement.hasOneTimeFees,
          ...(createAppPlansManagement.hasOneTimeFees &&
            createAppPlansManagement.planDuration.value !== PlanTypes.free && {
              one_time_fees: createAppPlansManagement.oneTimeFees,
            }),
          offer: null,
        },
      };

      const offerData: PlanOfferType = {
        id: currentlySelectedPlanOfferID.value !== 0 ? currentlySelectedPlanOfferID.value : null,
        offer_purchasable_id:
          currentlySelectedPlanOfferPurchasableID.value !== '' ? currentlySelectedPlanOfferPurchasableID.value : null,
      };

      if (isOfferApplied.value) {
        if (selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer) {
          offerData.offer_period = createAppPlansManagement.planOfferDuration.value;
          offerData.offer_type = PlanOfferTypeLabelsEnum.BuyXGetY;
        } else {
          offerData.offer_period = '30';
          offerData.offer_discount = +createAppPlansManagement.planDiscountOffer;
          offerData.offer_type = PlanOfferTypeLabelsEnum.Discount;
        }
        planInfo.pricingPlans.offer = offerData;
      } else {
        offerData.offer_period = null;
        planInfo.pricingPlans.offer = offerData;
      }
      const response = await sendPlanToAPI(planInfo, applicationId);
      if (!response.plan) {
        resetData();
        v$.value.$reset();
        Vue.$toast.error('Failed to add plan');
        return;
      }
      let newPlansList = [...plansList.plansList];
      // editing existing plan
      if (currentlySelectedPurchasableID.value !== '') {
        newPlansList = newPlansList.map((plan) => {
          if (plan.purchasable_id === currentlySelectedPurchasableID.value) plan = response.plan;
          return plan;
        });
      } else {
        // new plan
        newPlansList = [...newPlansList, response.plan];
      }
      await loadAppPlans();

      if (currentlySelectedPurchasableID.value !== '') Vue.$toast.success('Plan has been edited successfully');
      else Vue.$toast.success('Added new plan successfully');
    }
    resetData();
    v$.value.$reset();
    toggleShowAddPlanModal();
  };

  const onCloseAddOrEditPlanModal = () => {
    resetData();
    v$.value.$reset();
    toggleShowAddPlanModal();
  };

  const toggleOneTimeFeesSwitch = () => {
    createAppPlansManagement.hasOneTimeFees = !createAppPlansManagement.hasOneTimeFees;
    if (!createAppPlansManagement.hasOneTimeFees) createAppPlansManagement.oneTimeFees = '';
  };

  const showMessagePlanEnArField = computed(() => {
    return (
      (v$.value.planName.$dirty || v$.value.planNameArabic.$dirty) &&
      (v$.value.planName.$invalid || v$.value.planNameArabic.$invalid)
    );
  });

  return {
    PlanTypes,
    createAppPlansManagement,
    durationsList,
    offerableDurationsList,
    PlanStateSelectPropertiesEnum,
    trialDaysList,
    v$,
    isAddOrEditPlanModalButtonDisabled,
    showShowAddPlanModal,
    planType,
    PlanOfferTypeValuesEnum,
    selectedPlanOfferType,
    plansList,
    isOfferApplied,
    showDetailsSection,
    isAddPlanDisabled,
    showDeletePlanModal,
    loadingDeletePlan,
    currentlySelectedPurchasableID,
    showMessagePlanEnArField,
    PlansInvoicePeriodEnum,
    isLoadingPlansPage,
    onBackClicked,
    onSelectionDataChange,
    onInputDataChange,
    toggleShowAddPlanModal,
    onAddPlanClicked,
    onPlanRadioClick,
    onPlanOfferRadioClick,
    togglePlanOffer,
    onSaveFreePlan,
    onPlanDelete,
    onPlanEdit,
    onSavePlansAndContinue,
    onAddOrEditPlanClicked,
    validatePlanFeaturesEntry,
    toggleDeletePlanModal,
    onCloseAddOrEditPlanModal,
    onDeleteConfirmationClicked,
    toggleOneTimeFeesSwitch,
  };
};

export default usePlanManagement;
