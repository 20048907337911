import BaseModel from '../../../api/models/BaseModel';
import { CreateAppDetailsStepPayloadInterface } from '../types/create-app/createApp.enum';

class AppDetailsStepResponseModel extends BaseModel {
  public payload: CreateAppDetailsStepPayloadInterface;

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default AppDetailsStepResponseModel;
