import httpClient from '../../../../../api/httpClient';
import { GeneralSettingsInfoType } from '../../../types/create-app/createApp.enum';

const ENDPOINT_URL = '/market/app';

export const setGeneralSettingsDataToApi = (
  generalSettingsInfo: GeneralSettingsInfoType,
  applicationID: number | string,
) => {
  return httpClient
    .put(`${ENDPOINT_URL}/${applicationID}/scopes`, generalSettingsInfo)
    .then((response) => response)
    .catch((error) => error);
};
