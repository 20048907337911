<template>
  <div v-if="isLoadingAppData" class="w-full h-full flex justify-center items-center">
    <zid-loader size="large" />
  </div>
  <router-view v-else />
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ZidLoader } from '@zidsa/ui';

const { useGetters } = createNamespacedHelpers('application');

export default defineComponent({
  components: {
    ZidLoader,
  },
  setup() {
    const { isLoadingAppData } = useGetters(['isLoadingAppData']);

    return {
      isLoadingAppData,
    };
  },
});
</script>
