import BaseModel from '../../../api/models/BaseModel';

class DeletePlanResponseModel extends BaseModel {
  public deleted: number;

  constructor(arg: any) {
    super(arg);
    this.deleted = arg.deleted;
  }
}

export default DeletePlanResponseModel;
