export enum AppScopesTypes {
  account = 'account',
  orders = 'orders',
  webhooks = 'webhooks',
  categories = 'categories',
  vats = 'vats',
  products = 'products',
  countries_cities = 'countries_cities',
  customers = 'customers',
  deliveryOptions = 'deliveryOptions',
  abandonedCarts = 'abandonedCarts',
  payments = 'payments',
  coupons = 'coupons',
}

export enum CreateAppDetailInputs {
  appURL = 'appURL',
  redirectURL = 'redirectURL',
  callbackURL = 'callbackURL',
  shortDesciption = 'shortDesciption',
  shortDesciptionArabic = 'shortDesciptionArabic',
  videoURL = 'videoURL',
  developerName = 'developerName',
  developerNameArabic = 'developerNameArabic',
  storeLine = 'storeLine',
}

export enum PlanTypes {
  free = '0',
  paid = 'paid',
}

export type SubscribtionType = {
  id: number;
  webhookSelection: string[];
  targetURL: string;
  headers: string;
};

export type PlanType = {
  id: number;
  planName: string;
  planNameArabic: string;
  planDuration: {
    label: string;
    value: string;
  };
  planDiscountOffer: string;
  planOfferDuration: {
    label: string;
    value: string;
  };
  planDescription: string;
  pricePlan: string;
  trialDays: {
    label: string;
    value: string;
  };
  hasOneTimeFees: boolean;
  oneTimeFees: string;
  planFeatures: string;
};

export type GeneralSettingsInfoType = {
  scopes: number[];
  scopes_note: string;
};

export enum planStatePropertiesEnum {
  planName = 'planName',
  planNameArabic = 'planNameArabic',
  planDescription = 'planDescription',
  pricePlan = 'pricePlan',
  planFeatures = 'planFeatures',
  oneTimeFees = 'oneTimeFees',
  planDiscountOffer = 'planDiscountOffer',
}

export enum PlanStateSelectPropertiesEnum {
  PlanDuration = 'planDuration',
  PlanOfferDuration = 'planOfferDuration',
  TrialDays = 'trialDays',
}

export enum ScopeEnum {
  read = 0,
  readWrite = 1,
}

export type WebhookType = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
};

export type WebhookGroup = {
  group_id: string | null;
  webhooks: WebhookType[];
};

export type WebhookResponse = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
  app_id: number;
};

export type PlanInfoType = {
  id: number | null;
  purchasable_id: string | null;
  name_en: string;
  name_ar: string;
  description: string;
  features: string;
  price: string;
  duration: string;
  currency: string;
  trial_period: number;
  one_time_fees_purchasable_id: string | null;
  has_one_time_fees: boolean;
  one_time_fees?: string;
  offer: PlanOfferType | null;
};

export enum PlanOfferTypeLabelsEnum {
  BuyXGetY = 'buy_x_get_y',
  Discount = 'discount',
}

export enum PlanOfferTypeValuesEnum {
  BuyXGetYOffer = '1',
  DiscountOffer = '2',
}

export type PlanOfferType = {
  id: number | null;
  offer_purchasable_id: string | null;
  offer_type?: PlanOfferTypeLabelsEnum;
  offer_period?: string | null;
  offer_discount?: number;
};

export enum PlansInvoicePeriodEnum {
  monthly = 30,
  yearly = 365,
}

export enum AppListingStatusProperty {
  RequestToPublish = 1,
  Rejected = 2,
  Published = 3,
  Unpublished = 4,
}

export interface CreateAppDetailsStepPayloadInterface {
  id: number;
  icon: string;
  name: string;
  developer_name: string;
  rate: {
    avg_rate: number;
    total_rating: number;
  };
  short_description: string;
  description: string;
  category: string;
  plan: string;
  developer: {
    name: string;
    email: string;
    url: string;
    help: string;
    phone: string;
  };
  language: string;
  video: string | null;
  slider_images: string;
  screen_shots_ar: string;
  screen_shots_en: string;
  app_url: string;
  is_featured: boolean;
  is_html_enabled: boolean;
}

export enum EmbeddedAppScopeEnum {
  EmbeddedAppScope = 'embedded_apps_tokens_write',
}
